input,
textarea,
select,
fieldset {
  @apply mb-6;
  @apply w-full;
}

label,
.label {
    @apply block text-sm font-bold mb-2;
}

input,
select {
    @apply appearance-none border rounded w-full py-2 px-3 leading-tight border-grey;
}

input.error {
  @apply border-red;
}

.custom-select {
  @apply relative;
  @apply text-black;
  @apply w-full;
}

.custom-select select {
 @apply block px-3 pr-8 rounded leading-tight;
}

.custom-select .caret {
  @apply pointer-events-none absolute pin-y pin-r flex items-center px-2;
}

.e-btn.e-select.e-deletebutton {
  @apply bg-red;
  color: white;
}

/* SPECIAL CASE */

.input--reason-for-transfer .ajax__dropdown_frame_line,
.input--reason-for-transfer .ajax__dropdown_arrow {
  display: none;
}

.form-radio [type=radio]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  height: 1em;
  width: 1em;
  color: #01d0a2;
  background-color: #fff;
  border-color: #a5a2b2;
  border-width: 1px;
  @apply mr-2;
}

.form-radio [type=radio]:checked {
  background-image: url(data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e);
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}